import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { UserProvider } from './contexts/UserContext'; // Import UserProvider

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // Create root using the new API

// Wrap the App component with UserProvider
root.render(
  <UserProvider>
    <App />
  </UserProvider>
);
