import React, { createContext, useContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode'; // Import JWT decoding library

// Create the UserContext
const UserContext = createContext();

// Custom hook for accessing the context
export const useUser = () => useContext(UserContext);

// Context provider
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Store user data (e.g., name, profilePic)

  useEffect(() => {
    // Initialize user from token in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const expiryTime = decodedToken.exp * 1000; // Convert to milliseconds
        if (Date.now() < expiryTime) {
          // Token is still valid, set user context
          setUser({
            id: decodedToken.id,
            name: decodedToken.name,
            profilePic: decodedToken.profilePic,
            myLearnings: decodedToken.myLearnings,
            myTeachings: decodedToken.myTeachings,
          });
        } else {
          // Token has expired, clear it
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiry');
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        localStorage.removeItem('token');
      }
    }
  }, []); // Run only once when the component is mounted

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
